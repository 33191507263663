<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row mt-5">
      <div
        class="vx-col w-full lg:w-1/3 mb-5"
        v-for="(item, index) in formContract"
        :key="index"
      >
        <router-link to @click.native="redirectNew(item)">
          <project-card
            v-if="
              permissionPageAccessArry.includes(item.add) ||
              permissionPageAccessArry.includes(item.View) ||
              loginUserRole == 'Admin'
            "
            :icon="item.icon"
            type="area"
            :titleName="item.titleName"
          ></project-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from "@/components/project-cards/projectCard.vue";
import { BASEURL } from "@/config/index.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      isActive: false,
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
      excelurl: BASEURL(),
      archive: "",
      gridView: true,
      listView: false,
      searchField: "",
      searchQuery: "",
      role_name: "",
      analyticsData: [],
      formContract: [
        {
          titleName: "GST",
          icon: "LayersIcon",
          path: "/master/gst",
          roleName: [],
          routeName: "Platform",
          add: PermissionVariables.SettingsAdd,
          View: PermissionVariables.SettingsView,
        },
        {
          titleName: "Payment Mode",
          icon: "GlobeIcon",
          path: "/master/paymentMode",
          roleName: [],
          routeName: "Agreement",
          add: PermissionVariables.PaymentAdd,
          View: PermissionVariables.PaymentView,
        },
        {
          titleName: "PRIORITY",
          icon: "PlusCircleIcon",
          path: "/master/priority",
          roleName: [],
          routeName: "Platform",
          add: PermissionVariables.PriorityAdd,
          View: PermissionVariables.PriorityView,
        },
      ],
      isImp: false,
      show: false,
    };
  },

  methods: {
    redirectNew(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
  components: {
    ProjectCard,
  },
};
</script>

<style lang="scss"></style>
